.background-image {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 800px; /* Height of the image area */
    background-image: url('../Media/background.png'); /* Set your image URL here */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; /* Ensures the image covers the full area */
    overflow: hidden; /* Keeps the pseudo-element within the div */
}

.background-image:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 100%, white 10%); /* Gradient overlay */
    z-index: 1; 
}

.about-container {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
}


.about-h1{
    margin-top: 15%;
    padding-left: 5%;
    padding-bottom: 5%;
    color: white;
    text-align: left;
}

.about-main{
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    text-align: left;
}

.about-svg{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    background-color: orange;
}

.about-vision{
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(55, 52, 53, 1);
}

.about-p{
    width: 85%;
    display: flex;
    flex-direction:column;
    text-align: left;
    padding-top: 30px;
    padding-left:50px ;
    padding-right:50px ;
}

.about-svg1{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    background-color: orange;
}

.about-mission{
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(55, 52, 53, 1);
}

.about-svg2{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    background-color: orange;
}

.about-field{
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(55, 52, 53, 1);
}

.about-svg3{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    background-color: orange;

}

.about-client{
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(55, 52, 53, 1);
}

@media screen and (max-width: 768px) {

    .about-h1{
        margin-top: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .about-client{
        display: flex;
        text-align: center;
    }
}






