.contact-bcg-image {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 800px; /* Height of the image area */
    background-image: url('../Media/background.png'); /* Set your image URL here */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; /* Ensures the image covers the full area */
    overflow: hidden; /* Keeps the pseudo-element within the div */
}

.contact-bcg-imagee:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 100%, white 10%); /* Gradient overlay */
    z-index: 1; 
}

.main-contact{
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.container-contact{
    
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    text-align: left;
}

.h1-contact{
    margin-top: 15%;
    padding-left: 5%;
    padding-bottom: 5%;
    color: white;
    text-align: left;
}

  
.form-contact {
    font-weight: bold;
  }



.form-contact {
    display: flex;
    justify-content: center;
    padding-top: 5%;
    
}

.label-contact {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 430px;
    flex-shrink: 0;
    padding: 10px;
}

.label-contact label {
    display: none;
}

.label-contact input[type="text"],
.label-contact input[type="email"],
.label-contact input[type="text"] {
    color: #A2A2A2;
    font-family: sans-serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 25px;
    width: 100%;
    height: 62px;
    margin-bottom: 10px;
    border-radius: 15px;
    background: #ECECEC;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05) inset;
    padding: 10px;
    border: none;
    outline: none;
}

.label-contact input[type="text"]#message {
    height: 163px; 
}

.label-contact input[type="text"]::placeholder {
    color: #A2A2A2;
    font-style: italic;
}

.label-contact button {
    width: 103px;
    height: 38px;
    border-radius: 15px;
    background: #FFAA46;
    border: none;
    color: white;
    cursor: pointer;
    align-self: flex-end;
}

.additional-contact{
    width: 50%;
    text-align: left; 
    margin-right: 20px; 
    padding-left: 50px;
    flex-shrink: 0;
    color: #000; 
    font-family: sans-serif; 
    font-size: 16px; 
    font-style: normal; 
    font-weight: 400;
    line-height: 25px; 
}

.additional-contact p {
    margin-bottom: 5px;
}


@media screen and (max-width: 768px) {

        .h1-contact{
            white-space: nowrap;
            text-align:center ;
            margin-top: 50%;
            display: flex;
            justify-content: center;
        }   

        .main-contact {
          align-items: center;
          text-align: center;
          justify-content: center;
        }
      
        .container-contact {
          width: 100%;
          height: auto; 
        }
      
        .h1-contact {
          padding-top: 5%; 
        }
      
        .label-contact {
          width: 80%;
          max-width: 400px; 
          margin: auto; 
        }
      
        .additional-contact {
          width: 80%;
          max-width: 400px; 
          margin: auto;
     }

     .form-contact {
        display: flex;
        justify-content: center;
        flex-direction: column;
        }

        .additional-contact {
        width: 80%;
        padding-left: 10px;
        margin-right: 40px;
        }

        .additional-contact p {
            text-align: left;
        }
      
      
}