.product-bcg-image {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 800px; /* Height of the image area */
    background-image: url('../Media/background.png'); /* Set your image URL here */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; /* Ensures the image covers the full area */
    overflow: hidden; /* Keeps the pseudo-element within the div */
}

.product-bcg-image:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 100%, white 10%); /* Gradient overlay */
    z-index: 1; 
}

.products-container{
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.h1{
    margin-top: 15%;
    padding-left: 5%;
    padding-bottom: 5%;
    color: white;
    text-align: left;
}

.h1 h1{
    color: #FFF;
}

.main{
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    text-align: left;
}

.main p{
    width: 85%;
    padding-left:50px ;
    padding-right:50px ;
    text-align: left;
    justify-content: center;
    flex-direction: column;
}


.p{
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
    text-align: left;
    padding-top: 30px;
    padding-left:50px ;
    padding-right:50px ;
}

.icon1{
    display: inline-block;
    vertical-align: middle;
    color: rgba(42, 88, 164, 1);
    text-align: left;

}

.multi-poles{
    display: flex;
    justify-content: center;
    padding-top: 7%;
}

.multi-poles h1{
    color: rgba(55, 52, 53, 1);
}

.multi-p{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: left;
    padding-left:50px ;
    padding-right:50px ;
}


/* animation */
.slider {
    padding-top: 30px;
    width: 100%;
    min-height:375px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    overflow: hidden;

}

.slide-track {
    /* white-space: nowrap; */
    display: flex;
    width: calc(350px * 7); 
    /* max-width: 850%;  */
    animation: scroll 40s linear infinite;
}


.slide {
    height: 85%;
    width: 350px; 
    display: flex;
    justify-content: center;
    perspective: 100px;
    /* margin: 0 5px;  */
}


.slide-track:hover{
    animation-play-state:paused;
}


@keyframes scroll {
    0% {
        transform: translateX(0); 
    }
    100% {
        transform: translateX(calc(-350px * 7));
    }
}


/* .img-slide:hover{
    transform: translateZ(20px);
} */


.img-slide{
    width: 305px;
    height: 374px;
    display: flex;
    border-radius: 30px;
    transition: transform 1%;
    margin-right: 30px;
}

/* smartpoles */
.smart-poles{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    padding-top: 7%;
}

.smart-poles h1{
    color: rgba(55, 52, 53, 1);
}

.smart-poles p{
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    /* align-items: left; */
    padding-left:50px ;
    padding-right:50px ;
}


/* smartbech */
.smart-bench {
    width: 100%;
    padding-top: 7%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.photo-bench {
    width: 305px;
    height: 374px;
    flex-shrink: 0;
    border-radius: 30px; 
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}


/* smartbox */
.smart-box{  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 50px;
}

.box-image{
    width: 300px;
    height: 374px;
}

.box1-image{
    width: 300px;
    height: 374px;
} 


/* smartbox */
.smart-box{  
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    padding-top: 50px;
}

.box-image{
    width: 300px;
    height: 374px;
    flex-shrink: 0;
    border-radius: 30px;
}

.box1-image{
    width: 562px;
    height: 374px;
    flex-shrink: 0;
    border-radius: 30px;
}

/* pole-scheme */
.pole-scheme{
    display: flex;
    justify-content: center;
    align-items: center;
}


.pole{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    padding-top: 7%;
    color: rgba(55, 52, 53, 1);
}


/* Iot-platform */
.iot{
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(55, 52, 53, 1);
    /* padding-top: 7%; */
}

.iot-platform{
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;  
}


.iotb{
    padding-left: 90px;
    /* margin: auto; */
    display: flex;
    /* margin-top: 20px; */
    flex-direction: column;
    gap: 20px;
}

.iot-button {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    flex-shrink: 0;
    color: #FFF;
    font-family: Inter, sans-serif;
    border-radius: 20px;
    background: #9AC0FF;
    padding: 15px 25px; 
    border: none;
    outline: none;
    display: flex;
    justify-content: space-around;
 
}

@media screen and (max-width: 768px) {

    .h1{
        margin-top: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .main{
        display: flex;
        justify-content: space-between;
        flex-direction: column;

    }

     p{
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .multi-p{

        display: flex;
        justify-content: center;
        text-align: center;
    }

    .smart-bench{
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
    }

    .photo-bench{
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        flex-direction: column;
        
    }

    .smart-box{
        display: flex;
        justify-content: space-around;
        flex-direction: column;

    }
    
    .box1-image{
        width: 300px;
        height: 374px;
    }

    .diagrami{
        width: 500px;
        height: 500px;
    }

    .iot-platform{
        padding-top: 10%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .iotb{
    padding-left: 0; /* Hiq paddingun e krahut në mënyrë që butonat të jenë të qendruar në qendër */
    align-items: center; /* Vendosja e butonave në qendër horizontalisht */
    text-align: center; /* Qendrimi i tekstit në qendër për butonat nëse ata ndodhen në kolonë */
    }

}

  








