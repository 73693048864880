.home-container {
    background:url('../Media/bcg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* overflow: hidden; */
    flex-shrink: 0;
    overflow: hidden;
    
}


.home-paragraph{
   padding-left: 10%; 
   padding-top: 15%;
   padding-bottom: 22%;
   width: 100%;
   margin: auto;
   display: flex;
   flex-wrap: wrap;
   gap: 20px;
}

.home-paragraph1{
    color: #FFF;
    font-family: sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 85%;
    text-align:left;
    margin:0;
}

.home-paragraph2 {
    color: #FFF;
    font-family: sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;    
    text-align:left;
    /* margin:auto; */
    padding-top: 10px;
    width: 85%;
    display: flex;
    text-align: left;
    flex-wrap: wrap;
    gap: 20px;

}

.home-info{
    width: 85%;
    /* margin: auto; */
    padding-left: 10%; 
    color: #000;
    max-width: 730px;
    font: 400 16px/25px Inter, sans-serif;
    text-align: left;
    display: flex;
    /* flex-wrap: wrap; */
    align-items:left;
    /* gap: 20px; */
}

.home-cards{
    width: 85%;
    margin: auto;
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    border-radius: 30px;
    gap: 20px;
    color: #373435;
    font-family: Inter;
    font-size: 19.2px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* margin-left: 10px; */
   
}

.cards{
    color: #373435;
    font-family: sans-serif;
    font-size: 19.2px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.mern{
    aspect-ratio: 1.06;
    object-fit: auto;
    object-position: center;
    width: 100%;
    max-width: 228px;   
}

.home-btn{
    margin-top: 5%;
    flex-direction: column;
    font-size: 23px;
    font-weight: 500;
    line-height: normal;
    width: 211px;
    height: 56px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Inter, sans-serif;
    border-radius: 20px;
    background: #3374c0;
    justify-content: center;
    padding: 20px 43px;
    border: none;
    outline: none;
    text-decoration: none;
}

.home-services{
    margin: auto;
    width: 85%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
}

.photo-services{
    width: 94.423px;
    height: 89.995px;
    padding : 70px;
    width: 212px;
    border-top: 2px inset rgb(255,165,0);
    border-left: 1px inset rgb(255,165,0);
    border-right: 1px inset rgb(255,165,0);
    border-radius: 40px 40px 40px 40px;
    color: #000;
    font-family: Inter;
    font-size: 23.04px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.services{
    font-family: sans-serif;
}

.div-products{
    color: #000;
    text-align: center;
    line-height: 25px; 
    width: 85%;
    margin: auto;
    color: #000;
    max-width: 730px;
    font: 400 16px/25px Inter, sans-serif;
    /* margin-left: 150px; */
    /* text-align: left; */
    display: flex;
    flex-wrap: wrap;
    /* justify-content: left; */
    align-items:flex-start;
    gap: 20px;
    font: 400 16px/25px Inter, sans-serif;
}

.svg-container{
    aspect-ratio: 4.55;
    object-fit: auto;
    object-position: center;
    width: 100%;
    fill: linear-gradient(182deg, #e9922b 10.76%, rgba(255, 255, 255, 0) 58.86%);

}

.vector-products{
    width: 85%;
    margin: auto;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
   
}

.smart-photo{
    padding-top: 60px;
}

.btnsmart{
    width: 85%;
    margin: auto;
    display: flex;
    align-items: center; 
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 20px;
}

.btn{
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    flex-shrink: 0;
    color: #130606;
    font-family: Inter, sans-serif;
    
    border-radius: 20px;
    /* box-shadow: lightskyblue; */
    border-image: linear-gradient(180deg, rgba(51, 116, 192, 1), rgba(255, 251, 251, 0)) 1; 
    border-image-slice: 10;
    background-color: white;
    padding: 20px;    
    outline: none;
    }

    .home-contact {
        display: flex;
        justify-content: center;
        
    }

    .fotosmart{
        width: 100%;
        }
        
        
        .smartcity{
            position: relative;
            background: linear-gradient(180deg, #DCECFF 0.18%, #F3F8FF 33.88%, rgba(255, 255, 255, 0.00) 71.27%);
            width: 60%;
            margin:auto;
        }
        
        .centereds{
            color: #000;
            font-family: sans-serif;
            font-size: 23.04px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            position: absolute;
            top: 5%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        
        .fototesmart{
            width: 85%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            gap: 20px;
        }

  
h2{
    margin-top: 80px;
    color: #373435;
    font: 700 28px Inter, sans-serif;
}


.tele{
    aspect-ratio: 1.32;
    object-fit: auto;
    object-position: center;
    width: 100%;
    stroke-width: 1px;
    stroke: #e9922b;
    max-width: 331px;
}


.data{
    width: 94.423px;
    height: 89.995px;
}


    .container {
        max-width: 600px;
        margin: 0 auto;
      }
      
    .form-label {
        font-weight: bold;
      }

    .label-form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 430px;
        flex-shrink: 0;
        padding: 10px;
    }
    
    .label-form label {
        display: none;
    }
    
    .label-form input[type="text"],
    .label-form input[type="email"],
    .label-form input[type="text"] {
        color: #A2A2A2;
        font-family: sans-serif;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 25px;
        width: 100%;
        height: 62px;
        margin-bottom: 10px;
        border-radius: 15px;
        background: #ECECEC;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05) inset;
        padding: 10px;
        border: none;
        outline: none;
    }
    
    .label-form input[type="text"]#message {
        height: 163px; 
    }
    
    .label-form input[type="text"]::placeholder {
        color: #A2A2A2;
        font-style: italic;
    }
    
    .label-form button {
        width: 103px;
        height: 38px;
        border-radius: 15px;
        background: #FFAA46;
        border: none;
        color: white;
        cursor: pointer;
        align-self: flex-end;
    }
    
    .additional-text {
        width: 50%;
        text-align: left; 
        margin-right: 20px; 
        padding-left: 50px;
        flex-shrink: 0;
        color: #000; 
        font-family: sans-serif; 
        font-size: 16px; 
        font-style: normal; 
        font-weight: 400;
        line-height: 25px; 
    }
    
    .additional-text p {
        margin-bottom: 5px;
    }

    .map{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 20px;
        }

    .google-map{
        border: 0;
        background: lightgray 50% / cover no-repeat;
        border-radius: 30px;
    }


    
    @media screen and (max-width: 768px) {


        .home-paragraph{
            padding-top: 20%;
        }
      
       .map{

        padding-left:30px ;
        padding-right:30px ;
       }

       .home-contact {
        display: flex;
        justify-content: center;
        flex-direction: column;
        }

        .additional-text {
        width: 85%;
        padding-left: 10px;
        margin-right: 0;
        }

        .btnsmart{
        display: flex;
        justify-content: center;
        flex-direction: column;
        }

        .fototesmart{
            display: flex;
            flex-direction: column;
        }

        .additional-text{
            
            padding-right: 40px;
        }

        .fotosmart{
            width: 100%;
            height: 150px;
        }

        .centereds{
            padding-bottom: 40px;
        }

    

      }
      


    




    
        
    

     