.news-bcg-image {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 800px; /* Height of the image area */
    background-image: url('../Media/background.png'); /* Set your image URL here */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; /* Ensures the image covers the full area */
    overflow: hidden; /* Keeps the pseudo-element within the div */
}

.news-bcg-image:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 100%, white 10%); /* Gradient overlay */
    z-index: 1; 
}


.news-container{
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.news-h1{
    margin-top: 15%;
    padding-left: 5%;
    padding-bottom: 5%;
    color: white;
    text-align: left;
}

.news-main{
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    text-align: left;
}

.news1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5%;
}

.news2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5%;
}

.news3{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5%;
}

.news1 img{
    width: 374px;
    height: 351px;
    padding-left: 50px;
    border-radius: 30px;
}

.news2 img{
    width: 374px;
    height: 351px;
    padding-left: 50px;
    border-radius: 30px;
}

.news3 img{
    width: 374px;
    height: 351px;
    padding-left: 50px;
    border-radius: 30px;
}

.training-news{
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    padding-left: 10%;
    padding-bottom: 5%;
    padding-top: 30%;
    gap: 20px;
    color: white;
}

.atikos{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    color: rgba(55, 52, 53, 1);
    margin-bottom: 20%;
}

.atikos2{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    color: rgba(55, 52, 53, 1);
    margin-bottom: 20%;
}

.atikos3{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    color: rgba(55, 52, 53, 1);
    margin-bottom: 20%;
}

.atikos p{
    width: 70%;
    color: #000;
    font-family: normal;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; 
    font-family: sans-serif;
}

.atikos2 p{
    width: 70%;
    color: #000;
    font-family: normal;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; 
    font-family: sans-serif;
}

.atikos3 p{
    width: 70%;
    color: #000;
    font-family: normal;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; 
    font-family: sans-serif;
}

.btn-contact{
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: flex-end; 
    align-items: center; 
}
/* 
.arrow-contact, .arrow-contact1{
    
    width: 22px;
    height: 22px;
    fill: #888;
    margin: 0 5px; 
    border-radius: 50%; 
    
} */

.circle-contact {
    width: 86px;
    height: 35px;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; 
    text-align: center;
    border: 1px solid #888;
    border-radius: 30px;
    display: flex;
    align-items: center; 
    justify-content: center; 
  }

  .arrow-contact {
    margin-right: -20px;
  }

  .arrow-contact1 {
    margin-left: -20px;
  }

  .left-arrow,
  .right-arrow {
    display: flex;
    justify-content: center;
    width: 86px;
    height: 35px;
    fill: #888;
    margin: 0 5px;
    border-radius: 50%;
}

  


@media screen and (max-width: 768px) {
      
    .news-h1{
        margin-top: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }


    
      .news-items-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px; /* Hapësira midis artikujve */
      }
    
      .news-item {
        width: 85%; /* Përshtat gjeresinë e artikullit sipas prindit */
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .atikos p{
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;

    }
    
    .atikos2 p{
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }
        
    
    .atikos3 p{
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }

    .news1 img{
        width: 300px;
        height: 200px;
        padding-left: 5px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    
    .news2 img{
        width: 300px;
        height: 200px;
        padding-left: 5px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .news3 img{
        width: 300px;
        height: 200px;
        padding-left: 5px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .news1{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding-top: 15%;
    }
    
    .news2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding-top: 5%;
    }
    
    .news3{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding-top: 5%;
    }
    
    .btn-contact{
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .atikos2 h1{
        display: flex;
        justify-content: center;
        text-align: center;
    }

    
   }







