/* 
.header {
    z-index: 100;
    display: flex;
    width: 85%;
    padding: 13px 20px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    border-radius: 0px 0px 30px 30px;
    background: #FFF;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 0;
    left: 0;
    right: 0; 
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 768px) {

}




.button {
  display: flex;
  padding: 16px 25px;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: rgba(29, 29, 29, 0.80);
  font-size: 19.2px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  border-radius:20px;

}

.button:hover {
  border-radius: 30px;
  background: #c8cbd2;
  color: rgba(255, 255, 255, 0.80);

}


  .contact{
    display: flex;
    padding: 16px 25px;
    margin-left: 30px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: rgba(29, 29, 29, 0.80);
    font-size: 19.2px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 30px;
    background: black;
    color: rgba(255, 255, 255, 0.80);
    text-decoration: none
  }

  .icon{
    margin: 10px;
  }


  .icon2{
    transform: rotate;
  }


  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    
    display: flex;
    width: 295px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    border-radius: 20px 20px 20px 20px;
    background: #FFF;
    box-shadow: 0px 16px 74px -30px rgba(0, 0, 0, 0.40);
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    z-index: 1;
  }


  .dropdown-content a {
    display: flex;
    padding: 16px 20px;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: auto;
    display: flex;
    padding: 16px 20px;
    align-self: stretch;
    color: #333;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .dropdown-content  a{
    text-align: left;
    flex: 1 0 0;
    color: rgba(29, 29, 29, 0.80);
    font-family: "SF Pro Text";
    font-size: 19.2px;
    font-style:normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 30px 30px 30px 30px;
    font-family: sans-serif;
    
  }
  
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

  @media screen and (min-width: 700px) {
  
    .toggle{
      display: none;
    }

    
  }
 
 */










 /* .header {
  z-index: 100;
  display: flex;
  width: 85%;
  padding: 13px 20px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  border-radius: 0px 0px 30px 30px;
  background: #fff;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.button {
  display: flex;
  padding: 16px 25px;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: rgba(29, 29, 29, 0.80);
  font-size: 19.2px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  border-radius: 20px;
}

.button:hover {
  border-radius: 30px;
  background: #c8cbd2;
  color: rgba(255, 255, 255, 0.80);
}

.contact {
  display: flex;
  padding: 16px 25px;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: rgba(29, 29, 29, 0.80);
  font-size: 19.2px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 20px;
  background: black;
  color: rgba(255, 255, 255, 0.80);
  text-decoration: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 295px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  border-radius: 20px 20px 20px 20px;
  background: #fff;
  box-shadow: 0px 16px 74px -30px rgba(0, 0, 0, 0.40);
  background-color: #f9f9f9;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  display: flex;
  padding: 16px 20px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: auto;
  padding: 16px 20px;
  align-self: stretch;
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  flex: 1 0 0;
  color: rgba(29, 29, 29, 0.80);
  font-family: "SF Pro Text";
  font-size: 19.2px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 30px 30px 30px 30px;
  font-family: sans-serif;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.toggle {
  display: none;
}

.toggle-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.toggle-menu .button {
  display: flex;
  padding: 16px 25px;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: rgba(29, 29, 29, 0.80);
  font-size: 19.2px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  border-radius: 20px;
}

.toggle-menu .button:hover {
  border-radius: 30px;
  background: #c8cbd2;
  color: rgba(255, 255, 255, 0.80);
}

 @media only screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 70px;
  }

  .toggle {
    display: flex;
    margin-right: 20px;
    cursor: pointer;
  }

  .toggle-menu {
    display: none;
  }

  .header.show-menu .toggle-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .header.show-menu .button,
  .header.show-menu .contact,
  .header.show-menu .dropdown {
    display: flex;
  }

  .header.show-menu .toggle {
    display: none;
  }

  .header.show-menu .toggle-menu {
    display: flex;
  }
}  */









.header {
  z-index: 100;
  display: flex;
  width: 85%;
  padding: 13px 20px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  border-radius: 0px 0px 30px 30px;
  background: #fff;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.button {
  display: flex;
  padding: 16px 25px;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: rgba(29, 29, 29, 0.80);
  font-size: 19.2px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  border-radius: 20px;
}

.button:hover {
  border-radius: 30px;
  background: #c8cbd2;
  color: rgba(255, 255, 255, 0.80);
}

.contact {
  display: flex;
  padding: 16px 25px;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: rgba(29, 29, 29, 0.80);
  font-size: 19.2px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 20px;
  background: black;
  color: rgba(255, 255, 255, 0.80);
  text-decoration: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 295px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  border-radius: 20px 20px 20px 20px;
  background: #fff;
  box-shadow: 0px 16px 74px -30px rgba(0, 0, 0, 0.40);
  background-color: #f9f9f9;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  display: flex;
  padding: 16px 20px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: auto;
  padding: 16px 20px;
  align-self: stretch;
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  flex: 1 0 0;
  color: rgba(29, 29, 29, 0.80);
  font-family: "SF Pro Text";
  font-size: 19.2px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 30px 30px 30px 30px;
  font-family: sans-serif;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.toggle {
  display: none;
}

.toggle-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.toggle-menu .button {
  display: flex;
  padding: 16px 25px;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: rgba(29, 29, 29, 0.80);
  font-size: 19.2px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  border-radius: 20px;
}

.toggle-menu .button:hover {
  border-radius: 30px;
  background: #c8cbd2;
  color: rgba(255, 255, 255, 0.80);
}

@media only screen and (max-width: 1268px) {
  .button,
  .contact {
    display: none; /* Fshehi linqet dhe elementin e kontaktit në madhësi të vogël ekrani */
  }

  .toggle {
    display: flex; /* Shfaq toogle-n në vend të linqeve */
    margin-left: auto; /* Vendos toogle-n në anën e djathtë të headerit */
  }

  .header.show-menu .button,
  .header.show-menu .contact {
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* margin-top: 50px; */
  }

  .header img {
    position: absolute;
  }

  .header {
    flex-direction: column;
    min-height: 60px;
  }

  .header.show-menu .toggle {
    display: block;
    display: flex;
    justify-content: end;
  }

  .logo{
    display: none;
  }

  

  /* .button,
  .contact {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  } */

  /* .header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  } */

}



  